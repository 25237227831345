import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const Filter = () => {

    const filterTypeArr = ['premium','normal','intro','outro','legacy','regular','square','story']

    const filterType = useSelector(state => state.app.currentApp.filter);
    const filterUrl = useSelector(state => state.app.currentApp.endpoints.filter);
    const authToken = useSelector(state => state.auth.token);
    const [responseData,setResponseData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loader,setLoader] = useState({
       isLoading: false
    });

    const filterArr = typeof(filterType) !== 'undefined' ? Object.values(filterType) : '';
    const [filterData,setFilterData] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        start_date: false,
        end_date: false,
        type: 'users',
        category: ''
    });

    const [videoStats,setVideoStats] = useState({
       show_stats : false
    });

    const onInputChange = (e) =>{
       setFilterData({...filterData,[e.target.name]: e.target.value, category: ''});

       if(filterTypeArr.includes(e.target.value)){
            setVideoStats({show_stats: true});
       }else{
           setVideoStats({show_stats: false});
       }

    };

    const onSelectChange = (e) =>{
        setFilterData({...filterData,category: e.target.value});
    };

    const onFormSubmit = (e) =>{
        setLoader({isLoading: true});
      e.preventDefault();
        axios({
            method: 'POST',
            url: filterUrl,
            data: filterData,
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+authToken}
        }).then(res=>{
            if(res.data.status === true){
                setResponseData(res.data.data);
                setColumns(Object.keys(res.data.data[0]));
            }else{
                setResponseData([]);
                setColumns([]);
            }
            setLoader({isLoading: false});
        }).catch(error=>{
            console.log(error);
        })
    };

  return (
    <div>
        <div>
        <div id="app">
          <div className="main-wrapper main-wrapper-1">
            <div className="navbar-bg" />

            {/* Main Content */}
            <div className="main-content">
              <section className="section">


                <div className="section-header">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="section-header-breadcrumb-content">
                        <h1>Filter</h1>
                      </div>
                    </div>

                  </div>
                </div>
        <div className="card">
            <div className="card-header">
            <div className="card-title-wrap bar-primary">
                <h4 className="card-title mb-0">Custom Range to Filter Data</h4>
            </div>
            </div>
            <div className="card-body">
                <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                <div className="px-3">
                <input type="hidden" name="id" defaultValue />
                <div className="form-body">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12 mb-1">
                    <fieldset className="form-group text-left">
                        <label htmlFor="helperText">Start Date:</label>
                        <input type="date" id="helperText" name="start_date" className="form-control" onChange={(e)=>onInputChange(e)} required />
                    </fieldset>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 mb-1">
                    <fieldset className="form-group text-left">
                        <label htmlFor="helperText">End Date:</label>
                        <input type="date" id="helperText" name="end_date" className="form-control" onChange={(e)=>onInputChange(e)} required />
                    </fieldset>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                    <fieldset className="form-group text-left">
                        <label htmlFor="basicSelect">Type:</label>
                        <select className="form-control" id="basicSelect" name="type" onChange={(e)=>onInputChange(e)} required>
                            <option>Select Option</option>
                            {
                                filterArr ?
                                filterArr.map(filterValue =>(
                                        <option key={filterValue} value={filterValue}>{filterValue.replaceAll('_',' ').toUpperCase()}</option>
                                    ))
                                : ''
                            }
                        </select>
                    </fieldset>
                    </div>
                    { videoStats.show_stats ?
                        <div className="col-xl-4 col-lg-6 col-md-12">
                            <fieldset className="form-group text-left">
                                <label htmlFor="basicSelect">Video Type:</label>
                                <select className="form-control" id="basicSelect" name="type_video"
                                        onChange={(e) => onSelectChange(e)} required>
                                    <option disabled selected >Select Type</option>
                                    <option value="total_videos" >Total Videos</option>
                                    <option value="failed_videos" >Failed Videos</option>
                                    <option value="success_videos" >Success Videos</option>
                                    <option value="rendering_videos" >Rendering Videos</option>
                                    <option value="downloading_resources" >Downloading Resources Videos</option>
                                    <option value="awaiting_customization" >Awaiting Customization Videos</option>
                                    <option value="added_to_queue" >Added to Queue Videos</option>
                                </select>
                            </fieldset>
                        </div>
                        : ''
                    }
                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                    <fieldset className="form-group text-left">
                        <button type="submit" className="btn btn-primary pull-right">
                            { loader.isLoading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-filter" />} Filter
                        </button>
                    </fieldset>
                    </div>
                </div>
                </div>
            </div>
                </form>
            </div>


            </div>

             <div className="card">
                 <div className="card-header">
                     <h4 className="card-title mb-0">Custom Filter Data ({responseData.length})</h4>
                 </div>
                 <div className="card-body">
                     {
                        loader.isLoading ? <Skeleton height={35} count={10} /> :
                         <>
                             <div className="table-overflow">
                                 {
                                     <>
                                         <table className="table table-hover">
                                             <thead>
                                             <tr className="text-left">
                                                 {
                                                     columns.map((col,index) =>
                                                         <th key={index}>{col.replace('_',' ').toUpperCase()}</th>
                                                     )
                                                 }
                                             </tr>
                                             </thead>
                                             <tbody>
                                             {
                                                 Object.keys(responseData).length === 0 ? <tr>
                                                        <td>Data Not Found</td>
                                                     </tr> :
                                                 responseData.map(value =>
                                                     <tr className="text-left" key={value.id}>
                                                         {
                                                             columns.map((col,index) =>
                                                                 <td key={index}>{value[col]}</td>
                                                             )
                                                         }

                                                     </tr>
                                                 )
                                             }

                                             </tbody>
                                         </table>

                                     </>

                                 }
                             </div>
                         </>
                     }
                 </div>
             </div>


            </section>             
             </div>
          </div>
        </div>
      </div>        
    </div>
  );
}

export default Filter;

