import React, { useState } from "react";
import { loginUser } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Helmet from 'react-helmet';
import { Link, useHistory } from "react-router-dom";

const Login = () => {
  const auth = useSelector(state => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  let [user, setUser] = useState({
    email: false,
    password: false
  });

  const onInputChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }
  const onSubmitForm = e => {
    e.preventDefault();
    dispatch(loginUser(user));
  }

  if (auth.isAuthenticated) {
    history.push('/dashboard');
    window.location.reload();
  }
  return (
    <div className="section login-auth-bg">
      <Helmet>
        <title> Super-Admin | Login </title>
      </Helmet>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="login-brand login-brand-color pt-5">
                <img alt="image" src="../assets/images/logo.png" />
                Super Admin
              </div>
              <div className="card card-auth">
                <div className="card-header card-header-auth">
                  <h4>Login</h4>
                </div>
                <div className="card-body">
                  <form method="POST" action="#" onSubmit={e => onSubmitForm(e)} className="needs-validation" noValidate>
                    <div className="form-group text-left">
                      <label htmlFor="email">Email</label>
                      <input id="email" type="email" onChange={(e) => onInputChange(e)} className="form-control" name="email" tabIndex={1} required />
                      <div className="invalid-feedback">
                        Please fill in your email
                      </div>
                    </div>
                    <div className="form-group text-left">
                      <label htmlFor="password" >Password</label>
                      <div className="float-right">
                        <Link to="forget-password" className="text-small">
                          Forgot Password?
                        </Link>
                      </div>
                      <input id="password" type="password" onChange={(e) => onInputChange(e)} className="form-control" name="password" tabIndex={2} required />
                      <div className="invalid-feedback">
                        please fill in your password
                      </div>
                    </div>

                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-block btn-auth-color text-white" tabIndex={4}>
                        {
                          auth.loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''
                        }
                        {auth.loginText}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
