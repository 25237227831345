import React, {useEffect} from 'react'
import Helmet from 'react-helmet'
import {useSelector, useDispatch} from "react-redux";
import {reelAppUsers} from "../../store/actions/reelAppsActions";
import Stats from "./Stats";
import {fetchApps} from "../../store/actions/appActions";
import Loader from "../loader/Loader";

const Dashboard = () => {
    const dispatch = useDispatch();
    const currentApp = useSelector(state=> state.app.currentApp);
    useEffect(()=>{
        dispatch(fetchApps());
        if(currentApp) {
            dispatch(reelAppUsers(currentApp));
        }
    },[currentApp]);

    return (
        <div>
            <Helmet>
                <title> Super-Admin | Dashboard</title>
            </Helmet>

            { currentApp ? <Stats/> : <Loader/> }
        </div>
    );
}

export default Dashboard;
