import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {addArticle} from "../../store/actions/appActions";
import SummerNote from "./SummerNote";
import CKEditor from "ckeditor4-react";
import axios from "axios";

const AddArticle = () => {

    const dispatch = useDispatch();
    const authUser = useSelector(state => state.auth);
    const app = useSelector(state => state.app);

    const [category,setCategory] = useState([]);
    const [loader,setLoader] = useState({
        isLoading: false
    });

    const [articleTitle, setArticleTitle] = useState([{value: null}]);
    const [articleThumb, setArticleThumb] = useState([{file: null}]);
    const [articleContent, setArticleContent] = useState([{value: null}]);

    const [article,setArticle] = useState({
        app_id: app.currentApp.id,
        user_id: authUser.user.user_id,
        tags: '',
        category: ''
    });

    const onInputChange = (e) =>{
        setArticle({...article,[e.target.name]: e.target.value});
    };

    const onChangeImage = (e,index) =>{
        let tempThumb = [...articleThumb];
        let thumb = {...tempThumb[index]};
        thumb.file = e.target.files[0];

        tempThumb[index] = thumb;
        setArticleThumb(tempThumb);
    };

    const onInputTitle = (e,index) =>{
        let tempArr = [...articleTitle];
        let item = {...tempArr[index]};
        item.value = e.target.value;

        tempArr[index] = item;
        // setArticle({...article, title: tempArr})
        setArticleTitle(tempArr);
    };

    const onInputSummerNote = (value,index) =>{
        let tempNote = [...articleContent];
        let content = {...tempNote[index]};
        content.value = value;

        tempNote[index] = content;
        setArticleContent(tempNote);
    };

    const onInputFeature = (value,id) =>{
        const uid = id.toString();

        setArticle((val)=>({...val, feature: {...val.feature,[uid]:value}}));

    };


    const addSummerNote = (e) => {
        let tmpContent = [...articleContent];
        let tmpTitle = [...articleTitle];
        let tmpThumbnail = [...articleThumb];

        let contentArr = Array.from(tmpContent.concat({value: null}));
        let titleArr = Array.from(tmpTitle.concat({value: null}));
        let thumbnailArr = Array.from(tmpThumbnail.concat({file: null}));

        setArticleContent(contentArr);
        setArticleTitle(titleArr);
        setArticleThumb(thumbnailArr);
    }

    const onFormSubmit = (e) =>{
        e.preventDefault();
        setLoader({isLoading: true});

        const form = new FormData();
        form.append('app_id',article.app_id);
        form.append('user_id',article.user_id);
        form.append('name',article.tags);
        form.append('category',article.category);
        form.append('title',JSON.stringify(articleTitle));

        for (let i = 0; i < articleThumb.length; i++){
            form.append(`feature[${i}]`,articleThumb[i].file);
        }

        form.append('content',JSON.stringify(articleContent));
        dispatch(addArticle(form,setLoader));
    };

    useEffect(()=>{
        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/api/fetch-categories',
            data: {app_id: app.currentApp.id},
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+authUser.token}
        }).then(res=>{
            if(res.data.status === true){
                setCategory(res.data.data);
            }
        }).catch(error=>{
            console.log(error);
        });
    },[category]);

    return(
        <div>
            <Helmet>
                <title> Super-Admin | Add Video</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Add Article</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Article Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" onSubmit={(e) => onFormSubmit(e)} method="post" acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Tags:</label>
                                                                            <input type="text" id="helperText" onChange={(e) => onInputChange(e)} name="tags" className="form-control"  placeholder="Enter Tags" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Category:</label>
                                                                            <select id="select" name="category" className="form-control" required onChange={(e) => onInputChange(e)}>
                                                                                <option value="" selected disabled>-- Select Category --</option>
                                                                                {
                                                                                    category.length > 0 ?
                                                                                        category.map((item,index)=>{
                                                                                            return(
                                                                                                <option value={item.id} key={index}>{item.name}</option>
                                                                                            )
                                                                                        })
                                                                                    : ''
                                                                                }
                                                                            </select>
                                                                        </fieldset>
                                                                    </div>


                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1" id="addEndpoint">
                                                                        {
                                                                            articleContent.map((value,index) => (
                                                                                <>
                                                                                    <div className="col-xl-5 col-lg-5 col-md-12 mb-1">
                                                                                        <fieldset className="form-group text-left">
                                                                                            <label htmlFor="helperText">Title:</label>
                                                                                            <input type="text" onChange={(e) => onInputTitle(e, index)} name="text" className="form-control"  placeholder="Enter Title" required   />
                                                                                        </fieldset>
                                                                                    </div>

                                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                                        <fieldset className="form-group text-left">
                                                                                            <label htmlFor="helperText">Feature URL:</label>
                                                                                            <input type="file" id="helperText" onChange={(e) => onChangeImage(e,index)} name="file" className="form-control" required  />
                                                                                        </fieldset>
                                                                                    </div>

                                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                                                                        <CKEditor
                                                                                            name="editor"
                                                                                            initData=""
                                                                                            onChange={evt => onInputSummerNote( evt.editor.getData() , index)}
                                                                                        />

                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1 text-center">
                                                                        <button type="button" onClick={(e) => addSummerNote(e)} className="btn btn-primary fas fa-plus-circle" title="Add More"></button>
                                                                        {/*<button type="button" onClick={(e) => removeMoreEndpoint(e)} className="btn btn-info fas fa-minus-circle" title="Remove Endpoints"></button>*/}
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                {loader.isLoading ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Add Article
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default AddArticle;
