import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {editVideos} from "../../store/actions/appActions";
import axios from "axios";

const EditVideo = (props) => {

    const dispatch = useDispatch();

    const authUser = useSelector(state => state.auth);
    const app = useSelector(state => state.app);
    const params = props.match.params;

    const [loader,setLoader] = useState({
        isLoading: false
    });

    const [videosData,setVideosData] = useState({});
    const [videos,setVideos] = useState({
        id: params.id,
        app_id: app.currentApp.id,
        user_id: authUser.user.user_id,
        type: videosData.type,
        title: videosData.title,
        file: videosData.file,
        url: videosData.url
    });

    const onInputChange = (e) =>{
        setVideos({...videos,[e.target.name]: e.target.value});
    };

    const onChangeImage = (e) =>{
        setVideos({...videos,file: e.target.files[0]});
    };

    const onFormSubmit = (e) =>{
        e.preventDefault();

        setLoader({isLoading: true});

        const form = new FormData();
        form.append('id',videos.id);
        form.append('app_id',videos.app_id);
        form.append('user_id',videos.user_id);
        form.append('type',videos.type);
        form.append('title',videos.title);
        form.append('file',videos.file);
        form.append('url',videos.url);
        dispatch(editVideos(form,setLoader));
    };

    useEffect(()=>{
        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/api/fetch-video',
            data: {app_id: app.currentApp.id, id: params.id},
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+authUser.token}
        }).then(res=>{
            setVideosData(res.data.data);
            setVideos({
                ...videos,
                type: res.data.data.type,
                title: res.data.data.title,
                file: res.data.data.thumbnail,
                url: res.data.data.url,
            })
        }).catch(error=>{
            console.log(error);
        });
    },[]);

    return(
        <div>
            <Helmet>
                <title> Super-Admin | Add Video</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Add Video</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Video Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" onSubmit={(e) => onFormSubmit(e)} method="post" acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Video Type:</label>
                                                                            <select id="select" name="type" className="form-control" required onChange={(e) => onInputChange(e)}>
                                                                                <option selected={videosData.type === 'amazon'} defaultValue="amazon">Amazon</option>
                                                                                <option selected={videosData.type === 'vimeo'} defaultValue="vimeo">Vimeo</option>
                                                                                <option selected={videosData.type === 'playerneos'} defaultValue="playerneos">Playerneos</option>
                                                                            </select>
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Title:</label>
                                                                            <input type="text" id="helperText" value={videosData.title} onChange={(e) => onInputChange(e)} name="title" className="form-control"  placeholder="Enter Title" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Thumbnail: (leave blank if you don't want to update thumbnail)</label>
                                                                            <input type="file" id="helperText" onChange={(e) => onChangeImage(e)} name="file" className="form-control"  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Embedded Code:</label>
                                                                            <textarea id="helperText" onChange={(e) => onInputChange(e)} name="url" className="form-control"  placeholder="" >{videosData.url}</textarea>
                                                                        </fieldset>
                                                                    </div>


                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                {loader.isLoading ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Update Video
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default EditVideo;
