import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactTable from 'react-data-table-component';

const ViewUser = () => {

    const auth = useSelector(state => state.auth);
    const currentDiv = useSelector(state => state.reelapps.current_div);
    const dispatch = useDispatch();
    // Check User is root admin or not
    const history = useHistory();
    if (auth.user.is_root == 0) {
        dispatch({ type: 'ERROR', payload: 'Not Authorized to View this Location' });
        history.push('/dashboard');
    }

    const [adminData, setAdminData] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [loader, setLoader] = useState(true);
    const users = useSelector(state => state.auth.token);

    useEffect(() => {
        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/fetch-users',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + users }
        }).then(res => {
            setAdminData(res.data.data);
            setUserData(res.data.data)
            setLoader(false)
        }).catch(error => {
            console.log(error);
        });
    }, []);




    const setUserData = (allAdmin) => {
        
        if (currentDiv === "active_admins") {
            let data = []
            allAdmin.forEach((user) => {
                if (user.status === "1") {
                    data.push(user)
                }
            })
            setAdmins(data)
        }
        else if (currentDiv === "disable_admins") {
            let data = []
            allAdmin.forEach((user) => {
                if (user.status === "0") {
                    data.push(user)
                }
            })
            setAdmins(data)
        }
        else if (currentDiv === "root_level") {
            let data = []
            allAdmin.forEach((user) => {
                if (user.is_root === "1") {
                    data.push(user)
                }
            })
            setAdmins(data)
        }
        else if (currentDiv === "admin_level") {
            let data = []
            allAdmin.forEach((user) => {
                if (user.is_root === "0") {
                    data.push(user)
                }
            })
            setAdmins(data)
        }
        else {
            let data = []
            allAdmin.forEach((user) => {
                data.push(user)
            })
            setAdmins(data)
        }
    }


    const [q, setQ] = useState('');

    const search = (users) => {
        return users.filter(
            user => user.name || user.email ? user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 || user.email.toLowerCase().indexOf(q.toLowerCase()) > -1 : ''
        );
    };

    const getQ = (e) => {
        setQ(e.target.value)
    };


    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            grow: 0,
            wrap: true
        },
        {
            name: 'Name',
            selector: row => row.Name,
            grow: 1,
            wrap: true
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row.Status,
            grow: 0,
            wrap: true

        },
        {
            name: 'Type',
            selector: row => row.Type,
            grow: 0,
            wrap: true
        },
        {
            name: 'Register by',
            selector: row => row.Register_by,
            grow: 0,
            wrap: true
        },
        {
            name: 'Created',
            selector: row => row.Created,
            sortable: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.Action,
            button: true,
            grow: 0,
            wrap: true
        },

    ];

    const dynamicTableRow = (row) => {
        let data = []
        if (row) {
            search(row).forEach((ele, index) => {
                let myData = {
                    Id: parseInt(ele.id),
                    Name: < span class="d-flex align-items-center" > <img src={ele.avatar} class="mr-2 round" style={{ width: "20px" }}></img>{ele.name}</span >,
                    Email: ele.email,
                    Status: ele.status === "0" ? <i className="badge badge-danger">Disable</i> : <i className="badge badge-success">Active</i>,
                    Type: ele.is_root === "1" ? <i className="badge badge-primary">Root</i> : <i className="badge badge-secondary">Admin</i>,
                    Register_by: ele.register_by,
                    Created: ele.created,
                    Action: <Link to={`/edit-admin/${ele.id}`}> <i className="fa fa-pencil-alt" data-toggle="tooltip" data-original-title="edit user" /></Link>
                }
                data.push(myData);
            })
        }
        return data
    }
    // const data = []
    // if (admins) {
    //     search(admins).forEach((ele, index) => {
    //         let myData = {
    //             Id: ele.user_id,
    //             Name: <span class="d-flex align-items-center"> <img src={ele.profile_image} class="mr-2 round" style={{ width: "40px" }}></img>{ele.name}</span>,
    //             Email: ele.email,
    //             register_by: ele.register_by,
    //             type: < textarea value={JSON.stringify(ele.type)} className="form-control" readOnly={true} > {JSON.stringify(ele.type)}</textarea>,
    //             Created: ele.created,
    //             Action: <Link to={`/reelapp/user/${ele.user_id}`}> <i className="fa fa-pencil-alt" data-toggle="tooltip" data-original-title="edit user" /></Link>
    //         }
    //         data.push(myData);
    //     })
    // }

    return (
        <div>
            <Helmet>
                <title> Super-Admin | Users </title>
            </Helmet>
            <section>

                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <div className="navbar-bg" />


                        {/* Main Content */}
                        <div className="main-content">
                            <section className="section">
                                <div className="section-header">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="section-header-breadcrumb-content">
                                                <h1>DataTables</h1>
                                                <div className="section-header-breadcrumb">
                                                    <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                    <div className="breadcrumb-item"><a href="#">SuperAdmin</a></div>
                                                    <div className="breadcrumb-item"><a href="#">Users</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header d-flex justify-content-between">
                                                    <h4>Admins</h4>
                                                    <div className="search__user">
                                                        <input type="text" className="form-control" value={q} onChange={(e) => getQ(e)} placeholder="Search here..." />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive">

                                                        < ReactTable
                                                            columns={columns}
                                                            // data={data}
                                                            data={dynamicTableRow(admins)}
                                                            selectableRows
                                                            pagination
                                                            progressPending={loader}
                                                            responsive={true}
                                                            highlightOnHover={true}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </div>

                    </div>
                </div>

            </section>
        </div>
    )
};

export default ViewUser;
