import React from 'react'
import {useSelector} from "react-redux";


const Footer = () => {
    const auth = useSelector(state => state.auth);
    if (!auth.isAuthenticated) {
        return null;
    }
  return (
    <div>
        <section >
            <footer className="main-footer">
              <div className="footer-left">
                Copyright © 2020 <div className="bullet" /> Developed & Design By <a href="#">Vega6</a>
              </div>
              <div className="footer-right">
              </div>
            </footer>        
        </section>
    </div>
 );
}

export default Footer;
