import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StatusApplication from "./StatusApplication";
import ReactTable from "react-data-table-component";

const ViewApplication = () => {
  const allApplicationsData = useSelector((state) => state.app.apps);
  const currentDiv = useSelector((state) => state.reelapps.current_div);
  const currentApp = useSelector((state) => state.app.currentApp);
  const [applications, setApplications] = useState([]);

  const setApplicationData = (allApplications) => {
    if (allApplications) {
      if (currentDiv === "active_applications") {
        let data = [];
        allApplications.forEach((application) => {
          if (application.status === "1") {
            data.push(application);
          }
        });
        setApplications(data);
      } else if (currentDiv === "disabled_applications") {
        let data = [];
        allApplications.forEach((application) => {
          if (application.status === "0") {
            data.push(application);
          }
        });
        setApplications(data);
      } else {
        setApplications(allApplications);
      }
    }
  };

  useEffect(() => {
    setApplicationData(allApplicationsData);
  }, []);

  const [q, setQ] = useState("");

  const search = (applications) => {
    return applications.filter((user) =>
      user.name
        ? user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 
        : "" 
    );
  };

  const getQ = (e) => {
    setQ(e.target.value);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
      grow: 0,
      wrap: true,
      minWidth: "50px",
      maxWidth: "100%",
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      grow: 5,
      wrap: true,
      minWidth: "50px",
      maxWidth: "100%",
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      grow: 7,
      wrap: true,
      minWidth: "50px",
      maxWidth: "100%",
    },
    {
      name: "Domain",
      selector: (row) => row.Domain,
      grow: 7,
      wrap: true,
      minWidth: "50px",
      maxWidth: "100%",
    },
    {
      name: "Secret Key",
      selector: (row) => row.Secret_key,
      grow: 4,
      wrap: true,
      minWidth: "50px",
      maxWidth: "100%",
    },
    {
      name: "Created by",
      selector: (row) => row.Created_by,
      sortable: true,
      grow: 0,
      wrap: true,
      minWidth: "70px",
      maxWidth: "100%",
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      grow: 10,
      wrap: true,
      minWidth: "70px",
      maxWidth: "100%",
      button: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.Created_date,
      sortable: true,
      grow: 5,
      wrap: true,
      minWidth: "50px",
      maxWidth: "100%",
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      button: true,
      grow: 0,
    },
  ];

  const data = [];
  if (applications) {
    search(applications).forEach((ele, index) => {
      let myData = {
        Id: parseInt(ele.id),
        Name: (
          <span class="d-flex align-items-center">
            {" "}
            <img
              src={ele.logo}
              class="mr-2 round"
              style={{ width: "40px" }}
            ></img>
            {ele.name}
          </span>
        ),
        Description: (
          <textarea
            value={JSON.stringify(ele.description)}
            className="form-control"
            readOnly={true}
          ></textarea>
        ),
        Domain: (
          <textarea
            value={JSON.stringify(ele.domain)}
            className="form-control"
            readOnly={true}
          ></textarea>
        ),
        Secret_key: ele.secret_key,
        Created_by: parseInt(ele.user_id),
        Status: <StatusApplication application={ele} />,
        Created_date: ele.created,
        Action: (
          <Link to={`/edit-application/${ele.id}`}>
            {" "}
            <i
              className="fa fa-pencil-alt"
              data-toggle="tooltip"
              data-original-title="edit user"
            />
          </Link>
        ),
      };
      data.push(myData);
    });
  }

  return (
    <div>
      <Helmet>
        <title> Super-Admin | Users </title>
      </Helmet>
      <section>
        <div id="app">
          <div className="main-wrapper main-wrapper-1">
            <div className="navbar-bg" />
            {/* Main Content */}
            <div className="main-content">
              <section className="section">
                <div className="section-header">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="section-header-breadcrumb-content">
                        <h1>DataTables</h1>
                        <div className="section-header-breadcrumb">
                          <div className="breadcrumb-item active">
                            <a href="#">
                              <i className="fas fa-home" />
                            </a>
                          </div>
                          <div className="breadcrumb-item">
                            <a href="#">ReelApps</a>
                          </div>
                          <div className="breadcrumb-item">
                            <a href="#">Users</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <h4>Applications</h4>
                          <div className="search__user">
                            <input
                              type="text"
                              className="form-control"
                              value={q}
                              onChange={(e) => getQ(e)}
                              placeholder="Search here..."
                            />
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <ReactTable
                              columns={columns}
                              data={data}
                              selectableRows
                              pagination
                              progressPending={data.length === 0 ? true : false}
                              responsive={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewApplication;
