import React, {useEffect} from "react";
import {ToastContainer, toast, Slide} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";

const Toaster = () => {
    const notification = useSelector(state=> state.notification);
    const dispatch = useDispatch();

    const notify = (notify) => {
        const options = {
            position: notify.position,
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
        switch (notify.type) {
            case 'success':
                return toast.success(notify.message,options);
            case 'warning':
                return toast.warning(notify.message,options);
            case 'error':
                return toast.error(notify.message,options);
            case 'info':
                return toast.info(notify.message,options);
            default:
                return toast(notify.message,options);
        }

    }
    useEffect(()=>{
        notify(notification);
    },[notification]);

    if(notification.message !== false) {
        setTimeout(() => {
            dispatch({type: 'HIDE', payload: false});
        }, 15000);
    }

    return(
        <ToastContainer transition={Slide}/>
    )
}

export default Toaster;
