import React, { useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { addAdminUser } from "../../store/actions/authActions";
import { useHistory } from "react-router-dom";

const AddUser = () => {
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // Check User is root admin or not
  const history = useHistory();
  if (authUser.user.is_root == 0) {
    dispatch({
      type: "ERROR",
      payload: "Not Authorized to View this Location",
    });
    history.push("/dashboard");
  }

  const [chooseFile, setChooseFile] = useState("Choose File");
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    password: "",
    avatar: "",
    is_root: 1,
  });
  const [loader, setLoader] = useState({
    isLoading: false,
  });

  const onInputChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const enableDisable = (e) => {

    if (e.target.value == 1) {
      setAdmin({ ...admin, [e.target.name]: 0 });
    } else {
      setAdmin({ ...admin, [e.target.name]: 1 });
    }
  };

  const onChangeImage = (e) => {
    let allowedExt = ["image/png", "image/jpg", "image/jpeg"];
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      if (allowedExt.includes(e.target.files[0].type)) {
        setAdmin({ ...admin, logo: e.target.files[0] });
        setChooseFile(e.target.files[0].name);
      } else {
        window.alert("please enter valid image");
        setChooseFile("Choose File");
      }
    }
  };

  const onFormSubmit = (e) => {
    setLoader({ isLoading: true });
    e.preventDefault();
    const form = new FormData();
    form.append("name", admin.name);
    form.append("email", admin.email);
    form.append("password", admin.password);
    form.append("avatar", admin.avatar);
    form.append("is_root", admin.is_root);

    dispatch(addAdminUser(form, setLoader));
    history.push("/view-admin");
  };

  return (
    <div>
      <Helmet>
        <title> Super-Admin | Add Application</title>
      </Helmet>
      <section>
        <div>
          <div id="app">
            <div className="main-wrapper main-wrapper-1">
              <div className="navbar-bg" />
              {/* Main Content */}
              <div className="main-content">
                <section className="section">
                  <div className="section-header">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="section-header-breadcrumb-content">
                          <h1>Add Admin</h1>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-title-wrap bar-success">
                            <h4 className="card-title mb-0">Admin Details</h4>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="px-3">
                            <form
                              action=""
                              onSubmit={(e) => onFormSubmit(e)}
                              method="post"
                              acceptCharset="utf-8"
                            >
                              <div className="form-body">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Name:</label>
                                      <input
                                        type="text"
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="name"
                                        className="form-control"
                                        placeholder="Name"
                                        maxLength="25"
                                        required
                                      />
                                    </fieldset>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">Email:</label>
                                      <input
                                        type="text"
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        required
                                      />
                                    </fieldset>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">
                                        Password:
                                      </label>
                                      <input
                                        type="password"
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="password"
                                        className="form-control"
                                        placeholder="Password"
                                        required
                                      />
                                    </fieldset>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <div className="form-group text-left">
                                      <label htmlFor="logo-file">
                                        Upload Logo:
                                      </label>
                                      <label
                                        htmlFor="logo-file"
                                        className="input-group-text"
                                        data-toggle="tooltip"
                                      >
                                        {chooseFile}
                                      </label>
                                      <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="logo-file"
                                        onChange={(e) => onChangeImage(e)}
                                        name="logo"
                                        className="form-control"
                                        data-toggle="tooltip"
                                        title="PNG, JPEG, JPG File Within 5MB is allowed"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <div className="pretty p-switch">
                                        <input
                                          type="checkbox"
                                          name="is_root"
                                          defaultValue={admin.is_root}
                                          defaultChecked
                                          onChange={(e) => enableDisable(e)}
                                        />
                                        <div className="state p-warning">
                                          <label>
                                            {admin.is_root ? "Root" : "Admin"}
                                          </label>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>

                                  <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <button
                                        type="submit"
                                        className="btn btn-primary pull-right"
                                      >
                                        {loader.isLoading ? (
                                          <i className="fa fa-spinner fa-spin mr-2" />
                                        ) : (
                                          ""
                                        )}{" "}
                                        App Admin
                                      </button>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddUser;
