import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from '../../store/actions/authActions';

const ForgetPassword = () => {
  const auth = useSelector(state => state.auth);
  const [email, setEmail] = useState();

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const onInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClick = (e) => {
    setLoader(true);
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    dispatch(forgetPassword(email));
    setEmail(null);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  return (
    <div className="section login-auth-bg">
      <Helmet>
        <title> Super-Admin | Forget Password </title>
      </Helmet>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="login-brand login-brand-color pt-5">
                <img alt="image" src="../assets/images/logo.png" />
                Super Admin
              </div>
              <div className="card card-auth">
                <div className="card-header card-header-auth">
                  <h4>Forgot Password</h4>
                </div>
                <div className="card-body">
                  <p class="text-muted">We will send a link to reset your password</p>

                  <form method="POST" onSubmit={e => onSubmitForm(e)}>
                    <div className="form-group text-left">
                      <label htmlFor="email" className="control-label">Email</label>
                      <div className="float-right">
                        <Link to="login" className="text-small">
                          Remember Password! Login?
                        </Link>
                      </div>
                      <input id="email" type="email" className="form-control" onChange={(e) => onInputChange(e)} name="email" tabIndex={1} value={email} required autofocus />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-block btn-auth-color text-white" tabIndex={4} onClick={handleClick}>
                        {
                          loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''
                        } Forgot Password
                      </button>
                    </div>
                  </form>

                </div>
              </div>
              <div className="mt-5 text-muted text-center">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForgetPassword;
