import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeEmail } from "../../store/actions/reelAppsActions";

const ChangeEmail = ({ user, setRender }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        loader: false,
        buttonText: 'Update'
    });

    const [userEmail, setUserEmail] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        id: user?.user_id ? user?.user_id : user?.id,
        email: user?.email,
    });
    const OnInputChange = (e) => {
        setUserEmail({ ...userEmail, [e.target.name]: e.target.value });
    };
    const onSubmitForm = (e) => {
        setLoader({
            loader: true,
            buttonText: "Updating..."
        });
        e.preventDefault();
        dispatch(changeEmail(userEmail, setLoader, setRender));

    };



    return (
        <form onSubmit={(e) => onSubmitForm(e)}>
            <div className="row form-group ">
                <div className="col-lg-12 col-md-12 col-12 text-left">
                    <div className="form-group">
                        <label>Email</label>
                        <div className="input-group mb-3">
                            <input type="email" name="email" onChange={(e) => OnInputChange(e)} className="form-control"
                                defaultValue={user?.email} required />

                        </div>
                    </div>
                    <div className="col-12 mt-2 text-right pl-0 pr-0">
                        <button className="btn btn-primary" type="submit">
                            {loader.loader ? <i className="fa fa-spinner fa-spin mr-1" /> : ''}{loader.buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </form>

    )
}

export default ChangeEmail;
