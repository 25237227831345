import React, { useState, useEffect } from "react";

const TablePagination = ({ showPerPage, onPaginationChange, total_users }) => {

    const [counter, setCounter] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(Math.ceil(total_users / showPerPage));

    const [startIndex, setStartIndex] = useState(0);
    // const [endIndex, setEndIndex] = useState(numberOfPages < 10 ? numberOfPages : 10);
    const [endIndex, setEndIndex] = useState(numberOfPages);

    const [showNavLink, setShowNavLink] = useState(total_users);

    useEffect(() => {
        const end_value = showPerPage * counter;
        const start_value = end_value - showPerPage;
        onPaginationChange(start_value, end_value);

    }, [counter]);

    const updatePage = (key) => {
        setCounter(key);

        // ankit cmment this code
        // if (numberOfPages > endIndex) {
        //     setEndIndex(endIndex + 1);
        // }

        //setStartIndex(startIndex+1);
    };

    const getCounter = (type) => {
        if (type === 'prev') {
            if (counter === 1) {
                setCounter(1)
            } else {
                setCounter(counter - 1);
            }
        } else if (type === 'next') {
            if (numberOfPages === counter) {
                setCounter(counter);
            } else {
                setCounter(counter + 1);
            }
        }
    };

    return (
        <React.Fragment>
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item"><a className="page-link" onClick={() => getCounter('prev')}>Previous</a></li>
                    {
                        new Array(showNavLink).fill('').slice(startIndex, endIndex).map((val, key) => (
                            <li className={`page-item ${key + 1 === counter ? "active" : ""}`} key={key}>
                                <a className="page-link" onClick={() => updatePage(key + 1)}>{key + 1}</a>
                            </li>
                        ))
                    }
                    <li className="page-item"><a className="page-link" onClick={() => getCounter('next')}>Next</a></li>
                </ul>
            </nav>
        </React.Fragment>
    )
}

export default TablePagination;
