import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { MDBDataTableV5 } from 'mdbreact';
import ReactTable from 'react-data-table-component';

const Activity = () => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  // Check User is root admin or not
  const history = useHistory();
  if (auth.user.is_root === 0) {
    dispatch({ type: 'ERROR', payload: 'Not Authorized to View this Location' });
    history.push('/dashboard');
  }

  const [loader, setLoader] = useState({
    isLoading: true
  });

  const [datatable, setDatatable] = useState({
    columns: [
      {
        name: 'Id',
        selector: row => row.Id,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'User ID',
        selector: row => row.User_id,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'User Email',
        selector: row => row.User_email,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'Admin ID',
        selector: row => row.Admin_id,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'Admin Email',
        selector: row => row.Admin_email,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'Application Name',
        selector: row => row.Application_name,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'Reason',
        selector: row => row.Reason,
        sortable: true,
        grow: 0,
        wrap: true
      },
      {
        name: 'Data',
        selector: row => row.Data,
        sortable: true,
        grow: 1,
        wrap: true

      },
      {
        name: 'Created',
        selector: row => row.Created,
        sortable: true,
        grow: 0,
        wrap: true
      },
    ],
    rows: []
  });
  const [activity, setActivity] = useState({});

  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    axios({
      method: 'POST',
      url: 'https://adminserver.reelapps.io/fetch-admin-action',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token }
    }).then(res => {
      if (res.data.status === true) {
        setActivity(res.data.data);
        setDatatable({ ...datatable, rows: res.data.data })
      }
      setLoader({ isLoading: false })
    }).catch(error => {
      console.log(error);
      setLoader({ isLoading: false })
    })
  }, []);

  useEffect(() => {

  }, [datatable]);

  const [q, setQ] = useState('');

  const search = (activity) => {
    return activity.filter(
      user => user.action.toLowerCase().indexOf(q.toLowerCase()) > -1
    );
  };

  const getQ = (e) => {
    setQ(e.target.value)
  };



  const data = []
  if (datatable.rows) {
    datatable.rows.forEach((ele, index) => {
      let myData = {
        Id: ele.user_id,
        User_id: ele.user_id,
        User_email: ele.user_email,
        Admin_id: ele.admin_id,
        Admin_email: ele.admin_email,
        Application_id: ele.application_id,
        Application_name: ele.application_name,
        Action: ele.action,
        Reason: ele.reason,
        Data: ele.data,
        Created: ele.created,
      }
      data.push(myData);
    })
  }



  return (
    <div>

      <div>
        <div id="app">
          <div className="main-wrapper main-wrapper-1">
            <div className="navbar-bg" />
            {/* Main Content */}
            <div className="main-content">
              <section className="section">
                <div className="section-header">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="section-header-breadcrumb-content">
                        <h1>Activity Logs</h1>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>Admin/Root Activity</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          < ReactTable
                            columns={datatable.columns}
                            data={data}
                            selectableRows
                            pagination
                            progressPending={loader.isLoading}
                            responsive={true}
                            highlightOnHover={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </section>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;

