import React from 'react'
import Helmet from 'react-helmet'

const Loader = () => {
    return (
        <div>

            <section>
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg"/>
                            {/* Main Content */}
                            <div className="main-content">
                                <div className="spinner-border text-primary main-loader"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Loader;

