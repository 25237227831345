import React, {useState} from 'react'
import Helmet from 'react-helmet'
import {useDispatch} from "react-redux";
import {resetPassword} from "../../store/actions/authActions";

const ResetPassword = (props) => {

    const dispatch = useDispatch();
    const [password, setPassword] = useState({
        password: false,
        new_password: false,
        token: props.match.params.token
    });

    const onInputChange = (e) => {
        setPassword({...password,[e.target.name] : e.target.value})
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        dispatch(resetPassword(password));
        setPassword({
            password: false,
            new_password: false,
            token: props.match.params.token
        })
    };

  return (
    <div  className="section login-auth-bg">
        <Helmet>
          <title> Super-Admin | Login </title>
        </Helmet>
        <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="login-brand login-brand-color pt-5">
                <img alt="image" src="../assets/images/logo.png" />
                  Super Admin
              </div>
              <div className="card card-auth">
                <div className="card-header card-header-auth">
                <h4>Reset Password</h4>
                </div>
                <div className="card-body">
                <form method="POST" onSubmit={e => onSubmitForm(e)}>
                    <div className="form-group text-left">
                      <label htmlFor="password" className="control-label">New Password</label>
                      <input id="password" type="password" className="form-control pwstrength" data-indicator="pwindicator" onChange={(e) => onInputChange(e)} name="password" tabIndex={2} required />
                      <div id="pwindicator" className="pwindicator">
                        <div className="bar" />
                        <div className="label" />
                      </div>
                    </div>
                    <div className="form-group text-left">
                      <label htmlFor="password-confirm" className="control-label">Confirm Password</label>
                      <input id="password-confirm" type="password" className="form-control" name="new_password" onChange={(e) => onInputChange(e)} tabIndex={2} required />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-auth-color btn-lg btn-block text-white" tabIndex={4}>
                        Reset Password
                      </button>
                    </div>
                </form>

                                             
                </div>
              </div>
              <div className="mt-5 text-muted text-center">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResetPassword;
