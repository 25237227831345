const initialState = {
    loader: false,
    active_users: false,
    total_users: false,
    disabled_users: false,
    users: false,
    memberships: false,
    client_users: false,
    team_users: false,
    total_projects: false,
    success_projects: false,
    failed_projects: false,
    social_accounts: false,
    integrations: false,
    current_div: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "DIV_NAME":
            return {
                ...state,
                loader: false,
                current_div: action.payload,
            }
        case "REELAPPS_USERS":
            return {
                ...state,
                loader: false,
                users: action.payload.users,
                active_users: action.payload.active_users,
                total_users: action.payload.total_users,
                disabled_users: action.payload.disabled_users,
                client_users: action.payload.client_users,
                team_users: action.payload.team_users,
                total_projects: action.payload.total_projects,
                success_projects: action.payload.success_projects,
                failed_projects: action.payload.failed_projects,
                social_accounts: action.payload.social_accounts,
                integrations: action.payload.integrations,
                memberships: action.payload.membership_levels
            }
        case "LOADING_USERS":
            return {
                loader: true,
            }
        case 'ADD_REELAPP_USER':
            return {
                ...state,
                users: action.payload.users
            }
        case 'CHANGE_EMAIL':
            let index = state.users.findIndex(({ user_id }) => user_id === action.payload.id);
            state.users[index].email = action.payload.email;

            return { ...state }

        case 'UPDATE_USER':
            let user_index = state.users.findIndex(({ user_id, id }) => +user_id === +action.payload.id || +id === +action.payload.id);
            state.users[user_index].name = action.payload.name;
            state.users[user_index].type = action.payload.membership.join('__');
            state.users[user_index].user_type = action.payload.user_type;

            return { ...state }

        case 'UPDATE_USER_STATUS':
            let user_status = state.users.findIndex(({ user_id }) => user_id === action.payload.id);
            state.users[user_status].status = action.payload.status;
            return {
                ...state
            }
        case 'UPDATE_USER_DELETED':
            let user_deleted = state.users.findIndex(({ user_id }) => user_id === action.payload.id);
            state.users[user_deleted].deleted = action.payload.deleted;
            return {
                ...state
            }
        case 'UPDATE_USER_SUSPEND':
            let user_suspend = state.users.findIndex(({ user_id }) => user_id === action.payload.id);
            state.users[user_suspend].is_suspend = action.payload.suspend;
            state.users[user_suspend].suspend_reason = action.payload.reason;
            return {
                ...state
            }
        case 'UPDATE_USER_WHITELIST':
            let user_whitelist = state.users.findIndex(({ user_id }) => user_id === action.payload.id);
            state.users[user_whitelist].is_whitelist = action.payload.is_whitelist;
            return {
                ...state
            }

        default:
            return state;
    }
}
