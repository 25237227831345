const initialState = {
    loader: false,
    addLoader: false,
    buttonText: 'Submit',
    apps: false,
    currentApp: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'FETCH_APPS':
            return {
                ...state,
                loader: false,
                apps: action.payload
            }

        case 'ADD_APP':
            return {
                ...state,
                addLoader: false,
                apps: action.payload,
                buttonText: 'Submit'
            }
        case 'LOADING_APP':
            return {
                ...state,
                loader: true,
            }

        case 'CURRENT_APP':
            return {
                currentApp: action.payload
            }
        case 'ADD_APP_LOADER':
            return {
                ...state,
                buttonText: 'Adding...',
                addLoader: true,
            }
        case 'FETCH_ERROR':
            return {
                ...state,
                loader: false,
                addLoader: false,
                buttonText: 'Submit',
            }
        case 'UPDATE_APP':
            let index = state.apps.findIndex(({ id }) => id === action.payload.id);

            state.apps[index] = {
                ...state.apps[index],
                name: action.payload.name,
                domain: action.payload.domain,
                logo: action.payload.logo,
                secret_key: action.payload.secret_key,
                description: action.payload.description,
                user_id: action.payload.user_id
            }
            return {
                ...state
            }
        case 'UPDATE_APP_STATUS':
            let status_index = state.apps.findIndex(({ id }) => id === action.payload.id);
            state.apps[status_index].status = action.payload.status;
            return {
                ...state
            }

        default:
            return state;
    }
}
