import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateAppStatus } from "../../store/actions/appActions";

const StatusApplication = ({ application }) => {

    const dispatch = useDispatch();
    const [appStatus, setAppStatus] = useState({
        status: application.status,
        id: application.id
    });
    const enableDisable = (e) => {
        let id = e.target.id;
        if (appStatus.status === "1") {
            setAppStatus({ ...appStatus, status: "0", id: id });
            dispatch(updateAppStatus({ ...appStatus, status: "0", id: id }));
        } else {
            setAppStatus({ ...appStatus, status: "1", id: id });
            dispatch(updateAppStatus({ ...appStatus, status: "1", id: id }));
        }

    };

    useEffect(()=>{
        setAppStatus({
            status: application.status,
            id: application.id
        })
    },[application])
    return (
        // overflow: unset;
        <div>
            <div className="pretty p-switch" style={{ overflow: "unset", padding: "10px" }} >
                <input type="checkbox"  id={appStatus.id} checked={appStatus.status === "1"} onChange={(e) => enableDisable(e)} />
                <div className="state p-success">
                    <label></label>
                </div>
            </div>
        </div>
    )
};

export default StatusApplication;
