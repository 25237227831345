import React from "react";
import Helmet from "react-helmet";

const AddAppTraining = () => {
    return(
        <div id="app">
            <div className="main-content" >
                <section className="section">
                    <div className="section-header">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="section-header-breadcrumb-content">
                                    <h1>Add Application Tutorial</h1>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header"><h4>Steps to add application</h4></div>
                                <div className="section-body">
                                    <div className="activities mt-4 ml-4 mr-4">
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                1
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>
                                                    First create endpoints for the application with exactly same key value pairs and request response . You can see documentation for reference <a href="https://vega6api.postman.co/collections/6111803-bd15f8fe-5d49-43e5-9e57-bb6d48aaea84?version=latest&workspace=57fa8456-d20d-4eeb-8749-dbd63a72ec82#175b5dab-9991-4882-a0f3-7c0ee52613c2" target="_blank">here</a>.
                                                    <br/><b>Note</b>: This step to be done by developer you can proceed to 2nd step once developer gives you a confirmation to add that application.
                                                    All the endpoints needs to be done by the developer first.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                2
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>Add Application Name Ex:- Voicereel</p>
                                            </div>
                                        </div>
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                3
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>Add Application Domain URL Ex:- http://app.voicereel.io/</p>
                                            </div>
                                        </div>
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                4
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>Add Application Logo</p>
                                            </div>
                                        </div>
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                5
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>Add Application Secret Key, Which is Provided by Developer</p>
                                            </div>
                                        </div>
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                6
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>Add Application Description, How Application Works ?</p>
                                            </div>
                                        </div>
                                        <div className="activity">
                                            <div className="activity-icon activity-icon-linehight timeline-img-border bg-primary text-white">
                                                7
                                            </div>
                                            <div className="activity-detail text-left">
                                                <p>Final Step ! Click Add Application button for Adding Application</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AddAppTraining;
