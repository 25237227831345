import axios from "axios";
import store from "../store";

export const fetchApps = () => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-applications',
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'FETCH_APPS', payload: res.data.data });
            if (res.data.data.length !== 0) {
                if (getState().app.currentApp === false) {
                    dispatch({ type: 'CURRENT_APP', payload: res.data.data[0] });
                }
            }   
        } else {
            dispatch({ type: 'FETCH_ERROR' });
        }
    }).catch(error => {
        dispatch({ type: 'FETCH_ERROR' });
    });
};

export const changeApp = (id) => (dispatch, getState) => {
    let setApp = getState().app.apps.filter(app => app.id === id);
    const currentApp = setApp[0];
    dispatch({ type: 'CURRENT_APP', payload: currentApp });
};

export const addApp = (app, setApplication) => (dispatch, getState) => {
    dispatch({ type: 'ADD_APP_LOADER' });
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/add-application',
        data: app,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'ADD_APP', payload: res.data.data });
            dispatch({ type: 'SUCCESS', payload: res.data.message });
        } else {
            dispatch({ type: 'FETCH_ERROR' });
        }
        setApplication({ addLoader: false, buttonText: 'Add Application' })
    }).catch(error => {
        dispatch({ type: 'FETCH_ERROR' });
        setApplication({ addLoader: false, buttonText: 'Add Application' })
    });
};


export const updateApp = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/edit-application',
        data: app,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }
    }).then(res => {

        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'UPDATE_APP', payload: res.data.data });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
        setLoader({ loader: false });
    }).catch(error => {
        setLoader({ loader: false });
        console.log(error);
    })
};

export const updateAppStatus = (appStatus) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/app-status',
        data: appStatus,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'UPDATE_APP_STATUS', payload: res.data.data });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    }).catch(error => {
        console.log(error);
    })
};


export const addVideos = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/api/add-video',
        data: app,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }

    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    });

    setLoader({ isLoading: false });
};

export const editVideos = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/api/edit-video',
        data: app,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }

    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    });

    setLoader({ isLoading: false });
};


export const addCategory = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/api/add-category',
        data: app,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }

    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    });

    setLoader({ isLoading: false });
};


export const editCategory = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/api/edit-category',
        data: app,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }

    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    });

    setLoader({ isLoading: false });
};


export const addArticle = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/api/add-article',
        data: app,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + store.getState().auth.token }

    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    });

    setLoader({ isLoading: false });
};


export const editArticle = (app, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/api/edit-article',
        data: app,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + store.getState().auth.token }

    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            setLoader(false)
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    });

    setLoader({ isLoading: false });
};