import {combineReducers} from "redux";
import authReducer from "../reducers/authReducer";
import appReducers from "./appReducers";
import reelAppsReducers from "./reelAppsReducers";
import notificationReducers from "./notificationReducers";

const appReducer = combineReducers({
    auth: authReducer,
    app: appReducers,
    reelapps: reelAppsReducers,
    notification: notificationReducers
});


const rootReducers = (state, action) => {
    localStorage.clear();
    if (action.type === 'LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;
