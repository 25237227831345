import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

const EditWalkthrough = (props) => {

    const params = props.match.params;
    const authUser = useSelector(state => state.auth);
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);

    const [webinar, setWebinar] = useState({
        user_id: authUser.user.user_id,
        app_id: app.currentApp.id,
        demo_video: '',
        bg_video: '',
        status: '1'
    });

    const onInputChange = (e) => {
        setWebinar({...webinar, [e.target.name]: e.target.value});
    }

    const changeStatus = () => {
        if(webinar.status === '1')
            setWebinar({...webinar, status: '0'})
        else
            setWebinar({...webinar, status: '1'})
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/add-walkthrough',
            data: webinar,
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                dispatch({type: 'SUCCESS', payload: res.data.message});
            }else{
                dispatch({type: 'ERROR', payload: res.data.message});
            }
            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    useEffect(()=>{

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/walkthrough',
            data: {id: params.id},
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                setWebinar(res.data.data[0]);
            }
        }).catch(err=>{

        })

    },[])

    return(
        <>
            <Helmet>
                <title> Super-Admin | Edit Walkthrough</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Add Walkthrough</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content pull-right">
                                                    <Link to="/add-app-tutorial">
                                                        <i className="fa fa-info-circle"/> Need Help?
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Walkthrough Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" method="post" onSubmit={(e) => onFormSubmit(e)} acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">

                                                                    <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Demo Video</label>
                                                                            <input type="url" id="helperText" onChange={(e) => onInputChange(e)}
                                                                                  value={webinar.demo_video} name="demo_video" className="form-control"  placeholder="Enter Demo Video URl" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Background Video</label>
                                                                            <input type="url" id="helperText" onChange={(e) => onInputChange(e)}
                                                                                  value={webinar.bg_video} name="bg_video" className="form-control"  placeholder="Enter Background Video URL" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Status</label> <br />
                                                                            <input type="checkbox" id="helperTextemail" name="send_email" defaultValue={webinar.status} checked={webinar.status==="1"?true:false} onChange={(e)=>changeStatus(e)} /> {webinar.status==="1"?'Disable':'Enable'}<br />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                {loader ? <i className="fa fa-spinner fa-spin mr-1"/> : ''} Update Walkthrough
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(EditWalkthrough);