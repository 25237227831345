import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { addApp } from "../../store/actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import Endpoint from "./Endpoint";
import { Link } from "react-router-dom";
import axios from "axios";

const AddApplication = () => {
  const dispatch = useDispatch();
  const [application, setApplication] = useState({
    addLoader: false,
    buttonText: "Add Application",
  });
  const [chooseFile, setChooseFile] = useState("Choose File");
  const [app, setApp] = useState({
    name: "",
    domain: "",
    secret_key: "",
    logo: "",
    description: "",
    front_end: "",
    feature: [],
    endpoint: [],
  });

  const [endpointArr, setEndpointArr] = useState([
    {
      feature: "",
      endpoint: "",
    },
  ]);

  const onInputChange = (e) => {
    setApp({ ...app, [e.target.name]: e.target.value });
  };

  const onChangeImage = (e) => {
    let allowedExt = ["image/png", "image/jpg", "image/jpeg"];
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      if (allowedExt.includes(e.target.files[0].type)) {
        setApp({ ...app, logo: e.target.files[0] });
        setChooseFile(e.target.files[0].name);
      } else {
        window.alert("please enter valid image");
        setChooseFile("Choose File");
      }
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setApplication({ addLoader: true, buttonText: "Adding Application" });
    await endpointArr.map((cur) => {
      app.feature.push(cur.feature);
      app.endpoint.push(cur.endpoint);
    });
    const form = new FormData();
    form.append("name", app.name);
    form.append("domain", app.domain);
    form.append("secret_key", app.secret_key);
    form.append("logo", app.logo);
    form.append("description", app.description);
    form.append("front_end", app.front_end);
    form.append("feature", app.feature);
    form.append("endpoint", app.endpoint);
    dispatch(addApp(form, setApplication));
  };

  const onSelectChange = (value, index) => {
    let arr = [...endpointArr];
    arr[index].feature = value;
    setEndpointArr(arr);
  };

  const onInputEndpoint = (value, index) => {
    let arr = [...endpointArr];
    arr[index].endpoint = value;
    setEndpointArr(arr);
  };

  const removeSlide = (index) => {
    setEndpointArr(
      endpointArr.filter((curElem, id) => {
        return id !== parseInt(index);
      })
    );
  };

  /**
   * Append Endpoint Component
   * */
  let random_string = require("randomstring");

  /**
   * Remove Append Component
   * */
  const removeMoreEndpoint = (e) => {
    if (endpointArr.length > 1) {
      // setEndpointArr([endpointArr.pop()]);
    }
  };

  const addMoreEndpoint = (e) => {
    let arr = [...endpointArr];
    arr = [
      ...arr,
      {
        feature: "",
        endpoint: "",
      },
    ];
    setEndpointArr(arr);
  };
 // console.log(endpointArr);

  return (
    <div>
      <Helmet>
        <title> Super-Admin | Add Application</title>
      </Helmet>
      <section>
        <div>
          <div id="app">
            <div className="main-wrapper main-wrapper-1">
              <div className="navbar-bg" />
              {/* Main Content */}
              <div className="main-content">
                <section className="section">
                  <div className="section-header">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="section-header-breadcrumb-content">
                          <h1>Add Application</h1>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="section-header-breadcrumb-content pull-right">
                          <Link to="/add-app-tutorial">
                            <i className="fa fa-info-circle" /> Need Help?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-title-wrap bar-success">
                            <h4 className="card-title mb-0">
                              Application Details
                            </h4>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="px-3">
                            <form
                              action=""
                              method="post"
                              onSubmit={(e) => onFormSubmit(e)}
                              acceptCharset="utf-8"
                            >
                              <div className="form-body">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">
                                        Application Name:
                                      </label>
                                      <input
                                        type="text"
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="name"
                                        className="form-control"
                                        placeholder="Name"
                                        required
                                      />
                                    </fieldset>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">
                                        Domain Name:
                                      </label>
                                      <input
                                        type="text"
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="domain"
                                        className="form-control"
                                        placeholder="Domain"
                                      />
                                    </fieldset>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <div className="form-group text-left">
                                      <label htmlFor="logo-file">
                                        Upload Logo:
                                      </label>
                                      <label
                                        htmlFor="logo-file"
                                        className="input-group-text"
                                        data-toggle="tooltip"
                                      >
                                        {chooseFile}
                                      </label>
                                      <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="logo-file"
                                        onChange={(e) => onChangeImage(e)}
                                        name="logo"
                                        className="form-control"
                                        data-toggle="tooltip"
                                        title="PNG, JPEG, JPG File Within 5MB is allowed"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <div className="form-group text-left">
                                      <label htmlFor="front_end">
                                        Front End (Membership Name):
                                      </label>
                                      <input
                                        type="text"
                                        id="front_end"
                                        onChange={(e) => onInputChange(e)}
                                        name="front_end"
                                        className="form-control"
                                        data-toggle="tooltip"
                                        placeholder="Ex- (pro,lite etc)"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">
                                        Secret Key:
                                      </label>
                                      <input
                                        type="password"
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="secret_key"
                                        className="form-control"
                                        placeholder="*******"
                                        required
                                      />
                                    </fieldset>
                                  </div>

                                  <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <label htmlFor="helperText">
                                        Description:
                                      </label>
                                      <textarea
                                        id="helperText"
                                        onChange={(e) => onInputChange(e)}
                                        name="description"
                                        className="form-control"
                                        placeholder="Application Description"
                                      ></textarea>
                                    </fieldset>
                                  </div>

                                  <div
                                    className="col-xl-12 col-lg-12 col-md-12 mb-1"
                                    id="addEndpoint"
                                  >
                                    {endpointArr.map((curElem, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <Endpoint
                                            curElem={curElem}
                                            removeSlide={removeSlide}
                                            index={index}
                                            onSelectChange={onSelectChange}
                                            onInputEndpoint={onInputEndpoint}
                                          />
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                  <div className="col-xl-12 col-lg-12 col-md-12 mb-1 text-center">
                                    <button
                                      type="button"
                                      onClick={(e) => addMoreEndpoint(e)}
                                      className="btn btn-primary fas fa-plus-circle"
                                      title="Add More Endpoints"
                                    ></button>
                                    {/*<button type="button" onClick={(e) => removeMoreEndpoint(e)} className="btn btn-info fas fa-minus-circle" title="Remove Endpoints"></button>*/}
                                  </div>

                                  <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                    <fieldset className="form-group text-left">
                                      <button
                                        type="submit"
                                        className="btn btn-primary pull-right"
                                      >
                                        {application.addLoader ? (
                                          <i className="fa fa-spinner fa-spin" />
                                        ) : (
                                          ""
                                        )}{" "}
                                        {application.buttonText}
                                      </button>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddApplication;
