import React, {useState} from "react";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";

const EditFont = (props) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const params = props.match.params;
    const [fontName, setFontName] = useState({
        name: params.name,
        id: params.id
    })

    const onInputChange = (e) => {
        setFontName({...fontName, name: e.target.value});
    }


    const onFormSubmit = (e) =>{
        e.preventDefault();
        setLoader(true);

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/edit-font',
            data: fontName,
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                dispatch({type: 'SUCCESS', payload: 'Font Updated Successfully'});
            }else{
                dispatch({type: 'ERROR', payload: res.data.message});
            }
            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    return(
        <div>
            <Helmet>
                <title> Super-Admin | Edit Font</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Edit Font</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content pull-right">
                                                    <Link to="/add-app-tutorial">
                                                        <i className="fa fa-info-circle"/> Need Help?
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Font Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" method="post" onSubmit={(e) => onFormSubmit(e)} acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Name:</label>
                                                                            <input type="text" id="helperText" onChange={(e) => onInputChange(e)} name="name" className="form-control"
                                                                                   placeholder="Name" value={fontName.name} required  />
                                                                        </fieldset>
                                                                    </div>


                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                {loader ? <i className="fa fa-spinner fa-spin mr-1"/> : ''} Update Font
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default EditFont;