import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUserPassword } from "../../store/actions/reelAppsActions";


const UpdatePassword = ({ user, setRender }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        loader: false,
        buttonText: 'Updating'
    });

    const [userPassword, setUserPassword] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        password: '',
        confirm_password: '',
        id: user?.user_id ? user?.user_id : user?.id,
    });

    const onInputChange = (e) => {
        setUserPassword({ ...userPassword, [e.target.name]: e.target.value })
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, loader: true });
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (userPassword.password) {
            if (pattern.test(userPassword.password)) {
                if (userPassword.password === userPassword.confirm_password) {
                    dispatch(appUserPassword(userPassword, setLoader));
                    setUserPassword({ ...userPassword, password: '', confirm_password: '' });
                }
                else {
                    dispatch({ type: 'ERROR', payload: "Password is not matched!" })
                    setLoader(false)
                }
            } else {

                dispatch({ type: 'ERROR', payload: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and be at least 8 characters long." })
                setLoader(false)
            }
        } else {
            dispatch(appUserPassword(userPassword, setLoader, setRender));
            setUserPassword({ ...userPassword, password: '', confirm_password: '' });
        }

    };

    return (
        <form method="post" onSubmit={(e) => onFormSubmit(e)}>
            <div className="row form-group ">

                <div className="col-lg-6 col-md-6 col-12 text-left">
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" className="form-control"
                            defaultValue={userPassword.password}
                            onChange={(e) => onInputChange(e)}
                            placeholder="*****"
                            name="password" required />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 text-left">
                    <div className="form-group">
                        <label >Confirm Password</label>
                        <input type="password" className="form-control"
                            defaultValue={userPassword.confirm_password}
                            onChange={(e) => onInputChange(e)}
                            placeholder="*****"
                            name="confirm_password" />
                    </div>
                </div>
                <div className="col-12  text-right">
                    <button className="btn btn-primary" type="submit">
                        {loader.loader ? <i className="fa fa-spinner fa-spin mr-1" /> : ''} Update Password
                    </button>
                </div>
            </div>
        </form>
    )
}

export default UpdatePassword;
