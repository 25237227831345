import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import ReactTable from 'react-data-table-component';

const ViewWalkthrough = () => {

    const [walkthrough, setWalkthrough] = useState();

    useEffect(() => {

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/fetch-walkthrough',
            data: {},
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.data.status === true) {
                setWalkthrough(res.data.data);
            }
        }).catch(err => {

        })

    }, [])

    const columns = [
        {
            name: 'S No.',
            selector: row => row.Id,
            sortable: true,
            grow: 0,
            wrap: true
        },
        {
            name: 'Application',
            selector: row => row.Application,
            sortable: true,
            grow: 0,
            wrap: true
        },
        {
            name: 'Demo Video',
            selector: row => row.Demo_video,
            sortable: true,
            grow: 4,
            wrap: true
        },
        {
            name: 'Dashboard Video',
            selector: row => row.Dashboard_video,
            sortable: true,
            grow: 6,
            wrap: true

        },
        {
            name: 'Created',
            selector: row => row.Created,
            sortable: true,
            grow: 0,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.Action,
            button: true,
            grow: 0,
            wrap: true
        },

    ];

    const data = []
    if (walkthrough) {
        walkthrough.forEach((ele, index) => {
            let myData = {
                Id: ele.id, 
                Application: ele.app_name,
                Demo_video: < textarea value={JSON.stringify(ele.demo_video)} className="form-control" readOnly={true} > {JSON.stringify(ele.type)}</textarea>,
                Dashboard_video: < textarea value={JSON.stringify(ele.bg_video)} className="form-control" readOnly={true} > {JSON.stringify(ele.type)}</textarea>,
                Created: ele.created,
                Action: <Link to={`/edit-walkthrough/${ele.id}`}> <i className="fa fa-pencil-alt" data-toggle="tooltip" data-original-title="edit user" /></Link>
            }
            data.push(myData);
        })
    }



    return (
        <>
            <Helmet>
                <title> Super-Admin | View Walkthrough </title>
            </Helmet>
            <section>

                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <div className="navbar-bg" />


                        {/* Main Content */}
                        <div className="main-content">
                            <section className="section">
                                <div className="section-header">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="section-header-breadcrumb-content">
                                                <h1>Webinars</h1>
                                                <div className="section-header-breadcrumb">
                                                    <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                    <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                                                    <div className="breadcrumb-item"><a href="#">Webinar</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header d-flex justify-content-between">
                                                    <h4>Webinars</h4>
                                                    <div className="search__user">

                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        < ReactTable
                                                            columns={columns}
                                                            data={data}
                                                            selectableRows
                                                            pagination
                                                            progressPending={walkthrough ? false : true}
                                                            responsive={true}
                                                            highlightOnHover={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </div>

                    </div>
                </div>

            </section>
        </>
    )
}

export default React.memo(ViewWalkthrough);