import axios from "axios";
import store from "../store";

/**
 * This function will update Admin Actions log
 * */
export const adminAction = (actionData, setRender) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/add-admin-action',
        data: {
            action: actionData.action,
            app_id: store.getState().app.currentApp.id,
            user_id: actionData.user_id,
            reason: actionData.reason,
            user_email: actionData.user_email ? actionData.user_email : actionData.json_data.email,
            data: actionData.json_data !== '' ? actionData.json_data : null
        },
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.getState().auth.token }
    }).then(res => {
        if (setRender) {
            setRender((pre) => !pre)
        }
    }).catch(error => {
        console.log(error);
    })
};
