import React, {useState} from "react";
import Helmet from "react-helmet";

const AddFeature = () =>{

    const [loader,setLoader] = useState({
       status: false
    });
    const [feature, setFeature] = useState({
       name: ''
    });
    const onInputChange = (e) =>{
        setFeature({...feature,name: e.target.value})
    };

    return(
        <div>
            <Helmet>
                <title> Super-Admin | Add Application</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Add Feature</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Feature Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" method="post" acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">
                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Feature Name:</label>
                                                                            <input type="text" id="helperText" onChange={(e) => onInputChange(e)} name="name" className="form-control"  placeholder="Feature Name" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                App Feature
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default AddFeature;
