import React, {useState} from 'react'
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from "react-redux";
import {
    updateDeleted,
    updateEmail,
    updateName,
    updatePassword, updateProfile, updateRoot,
    updateStatus,
} from "../../store/actions/authActions";

const Profile = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    // Add Loader
    let [loaderEmail, setLoaderEmail] = useState({
        loader: false
    });
    let [loaderName, setLoaderName] = useState({
        loader: false
    });
    let [loaderPassword, setLoaderPassword] = useState({
        loader: false
    });



    // Define Const Form Variables
    const [userEmail , setUserEmail] = useState({
        email: auth.user.email,
        id: auth.user.user_id
    });
    const [userName, setUserName] = useState({
       name: auth.user.name,
       id: auth.user.user_id
    });
    const [userPassword, setUserPassword] = useState({
       id: auth.user.user_id,
       password: '',
       confirm_password: '',
       new_password: ''
    });
    const [userActive, setUserActive] = useState({
       id: auth.user.user_id,
       status: auth.user.status,
    });
    const [userDelete, setUserDelete] = useState({
        id: auth.user.user_id,
        deleted: auth.user.deleted
    });
    const [userRoot, setUserRoot] = useState({
        id: auth.user.user_id,
        is_root: auth.user.is_root
    });
    const [userProfile, setUserProfile] = useState({
       id: auth.user.user_id,
       file: ''
    });


    // ON Change Functions to get Input Values
    const onEmailChange = (e) => {
        setUserEmail({...userEmail,email: e.target.value})
    };
    const onNameChange = (e) => {
        setUserName({...userName,name: e.target.value});
    };
    const onInputChange = (e) => {
        setUserPassword({...userPassword,[e.target.name]: e.target.value})
    };
    const onProfile = (e) =>{
        setUserProfile({...userProfile,file: e.target.files[0]})
    };
    const enableDisable = (e) =>{

        if (userActive.status === "1") {
            setUserActive({...userActive, status: "0"});
            dispatch(updateStatus({...userActive,status: "0"}));
        } else {
            setUserActive({...userActive, status: "1"});
            dispatch(updateStatus({...userActive,status: "1"}));
        }
    };

    const rootStatus = (e) =>{
        if(userRoot.is_root === "1") {
            setUserRoot({...userRoot, is_root: "0"});
            dispatch(updateRoot({...userRoot,is_root: "0"}));
        } else {
            setUserRoot({...userRoot, deleted: "1"});
            dispatch(updateRoot({...userRoot,is_root: "1"}));
        }
    };

    const deleteUser = (e) =>{
        if(userDelete.deleted === "1") {
            setUserDelete({...userDelete, deleted: "0"});
            dispatch(updateDeleted({...userDelete,deleted: "0"}));
        } else {
            setUserDelete({...userDelete, deleted: "1"});
            dispatch(updateDeleted({...userDelete,deleted: "1"}));
        }

    };


    // On Submit Form Functions
    const onEmailSubmit = (e) =>{
        e.preventDefault();
        setLoaderEmail({loader: true});
        dispatch(updateEmail(userEmail,setLoaderEmail));
    };

    const onNameSubmit = (e) =>{
        e.preventDefault();
        setLoaderName({loader: true});
        dispatch(updateName(userName,setLoaderName));
    };

    const onPasswordSubmit = (e) => {
        e.preventDefault();
        setLoaderPassword({loader: true});
        dispatch(updatePassword(userPassword,setLoaderPassword))
    };

    const onProfileSubmit = (e) =>{
        e.preventDefault();
        const form = new FormData();
        form.append('id',userProfile.id);
        form.append('file',userProfile.file);
        dispatch(updateProfile(form));
    };



    return (
        <div>
            <Helmet>
                <title> Super-Admin | Profile</title>
            </Helmet>
            <section>
                <div className="main-content" style={{minHeight: '694px'}}>
                    <section className="section">
                        <div className="section-header">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="section-header-breadcrumb-content">
                                        <h1>Profile</h1>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                </div>
                            </div>
                        </div>
                        <div className="section-body">

                            <div className="row mt-sm-4">

                                <div className="col-12 col-md-12 col-lg-8">
                                    <div className="card">
                                        <div className="padding-20">
                                            <ul className="nav nav-pills mb-1" id="myTab2" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="profile-tab2" data-toggle="tab"
                                                       href="#settings" role="tab" aria-selected="false">Edit
                                                        Profile</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content tab-bordered" id="myTab3Content">

                                                <div className="tab-pane fade show active" id="settings" role="tabpanel"
                                                     aria-labelledby="profile-tab2">

                                                        <div className="card-header">
                                                            <h4>Edit Profile</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <form method="post" className="needs-validation" onSubmit={(e) => onNameSubmit(e)}>
                                                            <div className="row">
                                                                <div className="form-group col-md-12 col-12 text-left">
                                                                    <label>Name</label>
                                                                    <input type="text" className="form-control"
                                                                           defaultValue={auth.user.name}
                                                                           onChange={(e) => onNameChange(e)} />
                                                                    <div className="invalid-feedback">
                                                                        Please fill in the first name
                                                                    </div>
                                                                </div>
                                                            </div>
                                                              <div className="card-footer text-right">
                                                                <button type="submit" className="btn btn-primary">
                                                                    {loaderName.loader ?<i className="fa fa-spinner fa-spin" />:''} Update Name
                                                                </button>
                                                              </div>
                                                            </form>
                                                            <form method="post" className="needs-validation" onSubmit={(e) => onEmailSubmit(e)}>
                                                            <div className="row">
                                                                <div className="form-group col-md-7 col-12 text-left">
                                                                    <label>Email</label>
                                                                    <input type="email" className="form-control"
                                                                           defaultValue={auth.user.email}
                                                                           onChange={(e) => onEmailChange(e)} />
                                                                    <div className="invalid-feedback">
                                                                        Please fill in the email
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card-footer text-right">
                                                                <button className="btn btn-primary">
                                                                    {loaderEmail.loader ?<i className="fa fa-spinner fa-spin" />:''} Update Email
                                                                </button>
                                                            </div>
                                                            </form>

                                                            <form method="post" className="needs-validation" encType="multipart/form-data" onSubmit={(e) => onProfileSubmit(e)}>
                                                                <div className="row">
                                                                    <div className="form-group col-md-7 col-12 text-left">
                                                                        <label>Upload Profile</label>
                                                                        <input type="file" name="file" onChange={(e) => onProfile(e)} />
                                                                        <div className="invalid-feedback">
                                                                            Please fill in the email
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="card-footer text-right">
                                                                    <button className="btn btn-primary">
                                                                       Update Profile
                                                                    </button>
                                                                </div>
                                                            </form>

                                                            <form method="post" className="needs-validation" onSubmit={(e) => onPasswordSubmit(e)}>
                                                                <div className="row">
                                                                    <div className="form-group col-md-4 col-4 text-left">
                                                                        <label>Old Password</label>
                                                                        <input type="password" className="form-control"
                                                                               defaultValue={userPassword.password}
                                                                               name="password"
                                                                               onChange={(e) => onInputChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-4 col-4 text-left">
                                                                        <label>New Password</label>
                                                                        <input type="password" className="form-control"
                                                                               defaultValue={userPassword.new_password}
                                                                               name="new_password"
                                                                               onChange={(e) => onInputChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-4 col-4 text-left">
                                                                        <label>Confirm Password</label>
                                                                        <input type="password" className="form-control"
                                                                               defaultValue={userPassword.confirm_password}
                                                                               name="confirm_password"
                                                                               onChange={(e) => onInputChange(e)} />
                                                                    </div>
                                                                </div>

                                                                <div className="card-footer text-right">
                                                                    <button className="btn btn-primary">
                                                                        {loaderPassword.loader ?<i className="fa fa-spinner fa-spin" />:''} Update Password
                                                                    </button>
                                                                </div>
                                                            </form>

                                                        </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 col-md-12 col-lg-4">

                                    <div className="card mb-2">
                                        <div className="row author-box">
                                            <img alt="image" src={auth.user.avatar}
                                                 className="rounded-circle author-box-picture box-center mt-4"/>
                                        </div>
                                        <h5>  {auth.user.name} </h5>
                                        <h6> <i className="fa fa-envelope"/> {auth.user.email} </h6>
                                        <div className="card-body">
                                            <div className="pretty p-switch">
                                                <input type="checkbox" onChange={(e) => enableDisable(e)} checked={auth.user.status === "1"} />
                                                <div className="state p-warning">
                                                    <label>{auth.user.status === "1" ? "Enabled" : "Disabled"}</label>
                                                </div>
                                            </div>
                                            <div className="pretty p-switch">
                                                <input type="checkbox" onChange={(e) => deleteUser(e)} checked={auth.user.deleted === "1"}/>
                                                <div className="state p-danger">
                                                    <label>{auth.user.deleted === "1" ? "Deleted" : "Delete"}</label>
                                                </div>
                                            </div>
                                            {
                                                auth.user.is_root === '1' ?
                                                <div className="pretty p-switch">
                                                    <input type="checkbox" onChange={(e) => rootStatus(e)}
                                                           checked={auth.user.is_root === "1"}/>
                                                    <div className="state p-danger">
                                                        <label>{auth.user.is_root === "1" ? "Root" : "Admin"}</label>
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="settingSidebar">
                        <a onClick={e => e.preventDefault()} className="settingPanelToggle"> <i className="    fas fa-adjust"/>
                        </a>
                        <div className="settingSidebar-body ps-container ps-theme-default" tabIndex={2}
                             style={{overflow: 'hidden', outline: 'none'}}>
                            <div className=" fade show active">
                                <div className="setting-panel-header">Theme Customizer</div>
                                <div className="p-15 border-bottom">
                                    <h6 className="font-medium m-b-10">Theme Layout</h6>
                                    <div className="selectgroup layout-color w-50">
                                        <label> <span className="control-label p-r-20">Light</span>
                                            <input type="radio" name="custom-switch-input" defaultValue={1}
                                                   className="custom-switch-input" defaultChecked/> <span
                                                className="custom-switch-indicator"/>
                                        </label>
                                    </div>
                                    <div className="selectgroup layout-color  w-50">
                                        <label> <span className="control-label p-r-20">Dark&nbsp;</span>
                                            <input type="radio" name="custom-switch-input" defaultValue={2}
                                                   className="custom-switch-input"/> <span
                                                className="custom-switch-indicator"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Sidebar Colors</h6>
                                <div className="sidebar-setting-options">
                                    <ul className="sidebar-color list-unstyled mb-0">
                                        <li title="white" >
                                            <div className="white"/>
                                        </li>
                                        <li title="blue">
                                            <div className="blue"/>
                                        </li>
                                        <li title="coral">
                                            <div className="coral"/>
                                        </li>
                                        <li title="purple" className="active">
                                            <div className="purple"/>
                                        </li>
                                        <li title="allports">
                                            <div className="allports"/>
                                        </li>
                                        <li title="barossa">
                                            <div className="barossa"/>
                                        </li>
                                        <li title="fancy">
                                            <div className="fancy"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Theme Colors</h6>
                                <div className="theme-setting-options">
                                    <ul className="choose-theme list-unstyled mb-0">
                                        <li title="white" className="active">
                                            <div className="white"/>
                                        </li>
                                        <li title="blue">
                                            <div className="blue"/>
                                        </li>
                                        <li title="coral">
                                            <div className="coral"/>
                                        </li>
                                        <li title="purple">
                                            <div className="purple"/>
                                        </li>
                                        <li title="allports">
                                            <div className="allports"/>
                                        </li>
                                        <li title="barossa">
                                            <div className="barossa"/>
                                        </li>
                                        <li title="fancy">
                                            <div className="fancy"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Layout Options</h6>
                                <div className="theme-setting-options">
                                    <label> <span className="control-label p-r-20">Compact
                                                    Sidebar Menu</span> <input type="checkbox" name="custom-switch-checkbox"
                                               className="custom-switch-input" id="mini_sidebar_setting"/> <span
                                        className="custom-switch-indicator"/>
                                    </label>
                                </div>
                            </div>
                            <div className="mt-3 mb-3 align-center">
                                <a href="#" className="btn btn-icon icon-left btn-outline-primary btn-restore-theme">
                                    <i className="fas fa-undo"/> Restore Default
                                </a>
                            </div>
                        </div>
                        <div id="ascrail2001" className="nicescroll-rails nicescroll-rails-vr" style={{
                            width: '8px',
                            zIndex: 100,
                            cursor: 'default',
                            position: 'absolute',
                            top: '0px',
                            left: '272px',
                            height: '375px',
                            display: 'block',
                            opacity: 0
                        }}>
                            <div className="nicescroll-cursors" style={{
                                position: 'relative',
                                top: '0px',
                                float: 'right',
                                width: '6px',
                                height: '266px',
                                backgroundColor: 'rgb(66, 66, 66)',
                                border: '1px solid rgb(255, 255, 255)',
                                backgroundClip: 'padding-box',
                                borderRadius: '5px'
                            }}/>
                        </div>
                        <div id="ascrail2001-hr" className="nicescroll-rails nicescroll-rails-hr" style={{
                            height: '8px',
                            zIndex: 100,
                            top: '367px',
                            left: '0px',
                            position: 'absolute',
                            cursor: 'default',
                            display: 'none',
                            width: '272px',
                            opacity: 0
                        }}>
                            <div className="nicescroll-cursors" style={{
                                position: 'absolute',
                                top: '0px',
                                height: '6px',
                                width: '280px',
                                backgroundColor: 'rgb(66, 66, 66)',
                                border: '1px solid rgb(255, 255, 255)',
                                backgroundClip: 'padding-box',
                                borderRadius: '5px',
                                left: '0px'
                            }}/>
                        </div>
                    </div>
                </div>
            </section>


        </div>

    );
}
export default Profile;
