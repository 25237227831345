const initialize = {
    type: 'success',
    message: false,
    duration: 15000,
    position: 'bottom-right',
}

export default function (state = initialize, action) {
    switch (action.type) {
        case 'SUCCESS':
            return {
                ...state,
                type: 'success',
                message: action.payload
            }
        case 'WARNING':
            return {
                ...state,
                type: 'warning',
                message: action.payload
            }
        case 'INFO':
            return {
                ...state,
                type: 'info',
                message: action.payload
            }
        case 'ERROR':
            return {
                ...state,
                type: 'error',
                message: action.payload
            }
        case 'HIDE':
            return{
                ...state,
                message: false
            }
        default:
            return state;
    }
}
