import React, {useState} from "react";
import Helmet from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {addCategory} from "../../store/actions/appActions";

const AddCategory = () => {

    const dispatch = useDispatch();
    const authUser = useSelector(state => state.auth);
    const app = useSelector(state => state.app);

    const [loader,setLoader] = useState({
        isLoading: false
    });
    const [category,setCategory] = useState({
        app_id: app.currentApp.id,
        user_id: authUser.user.user_id,
        name: '',
        logo: ''
    });

    const onInputChange = (e) =>{
        setCategory({...category,[e.target.name]: e.target.value});
    };

    const onChangeImage = (e) =>{
        setCategory({...category,logo: e.target.files[0]});
    };

    const onFormSubmit = (e) =>{
        e.preventDefault();
        setLoader({isLoading: true});

        const form = new FormData();
        form.append('app_id',category.app_id);
        form.append('user_id',category.user_id);
        form.append('name',category.name);
        form.append('logo',category.logo);

        dispatch(addCategory(form,setLoader));
    };

    return(
        <div>
            <Helmet>
                <title> Super-Admin | Add Category</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Add Category</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Category Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" onSubmit={(e) => onFormSubmit(e)} method="post" acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Category Name:</label>
                                                                            <input type="text" id="helperText" onChange={(e) => onInputChange(e)} name="name" className="form-control"  placeholder="Enter Name" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Upload Logo:</label>
                                                                            <input type="file" id="helperText" onChange={(e) => onChangeImage(e)} name="logo" className="form-control" required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                {loader.isLoading ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Add Category
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default AddCategory;
