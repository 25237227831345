import React from 'react'
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';


const ThemeSettings = () => {
    const auth = useSelector(state => state.auth);
    if (!auth.isAuthenticated) {
        return null;
    }
    return (
        <div>
            <section>
                <div className="settingSidebar" data-show="false" >
                    <Link to="#"  className="settingPanelToggle"> 
                    <i className="fas fa-adjust p-none"   />
                    </Link>
                    <div className="settingSidebar-body ps-container ps-theme-default">
                        <div className=" fade show active">
                            <div className="setting-panel-header">Theme Customizer</div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Theme Layout</h6>
                                <div className="selectgroup layout-color w-50">
                                    <label> <span className="control-label p-r-20">Light</span>
                                        <input type="radio" name="custom-switch-input" defaultValue={1}
                                               className="custom-switch-input" defaultChecked/> <span
                                            className="custom-switch-indicator"/>
                                    </label>
                                </div>
                                <div className="selectgroup layout-color  w-50">
                                    <label> <span className="control-label p-r-20">Dark&nbsp;</span>
                                        <input type="radio" name="custom-switch-input" defaultValue={2}
                                               className="custom-switch-input"/> <span
                                            className="custom-switch-indicator"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="p-15 border-bottom">
                            <h6 className="font-medium m-b-10">Sidebar Colors</h6>
                            <div className="sidebar-setting-options">
                                <ul className="sidebar-color list-unstyled mb-0">
                                    <li title="white" className="active">
                                        <div className="white"/>
                                    </li>
                                    <li title="blue">
                                        <div className="blue"/>
                                    </li>
                                    <li title="coral">
                                        <div className="coral"/>
                                    </li>
                                    <li title="purple">
                                        <div className="purple"/>
                                    </li>
                                    <li title="allports">
                                        <div className="allports"/>
                                    </li>
                                    <li title="barossa">
                                        <div className="barossa"/>
                                    </li>
                                    <li title="fancy">
                                        <div className="fancy"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="p-15 border-bottom">
                            <h6 className="font-medium m-b-10">Theme Colors</h6>
                            <div className="theme-setting-options">
                                <ul className="choose-theme list-unstyled mb-0">
                                    <li title="white" className="active">
                                        <div className="white"/>
                                    </li>
                                    <li title="blue">
                                        <div className="blue"/>
                                    </li>
                                    <li title="coral">
                                        <div className="coral"/>
                                    </li>
                                    <li title="purple">
                                        <div className="purple"/>
                                    </li>
                                    <li title="allports">
                                        <div className="allports"/>
                                    </li>
                                    <li title="barossa">
                                        <div className="barossa"/>
                                    </li>
                                    <li title="fancy">
                                        <div className="fancy"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="p-15 border-bottom">
                            <h6 className="font-medium m-b-10">Layout Options</h6>
                            <div className="theme-setting-options">
                                <label> <span className="control-label p-r-20">Compact
                          Sidebar Menu</span> <input type="checkbox" name="custom-switch-checkbox"
                                                     className="custom-switch-input" id="mini_sidebar_setting"/> <span
                                    className="custom-switch-indicator"/>
                                </label>
                            </div>
                        </div>
                        <div className="mt-3 mb-3 align-center">
                            <a href="#" className="btn btn-icon icon-left btn-outline-primary btn-restore-theme">
                                <i className="fas fa-undo"/> Restore Default
                            </a>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default ThemeSettings;


{/* */
}
