import React from 'react'
import Datatable from "../datatable/Datatable";

const ViewUser = () => {


  return (
    <div>
        <Datatable/>
    </div>
  );
}

export default ViewUser;
