import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/actions/authActions";
import { Link } from "react-router-dom"


const Sidebar = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    if (!auth.isAuthenticated) {
        return null;
    }
    const logout = (e) => {
        dispatch(logoutUser());
    };

    return (
        <div>
            <section>
                <div className="main-sidebar sidebar-style-2">
                    <aside id="sidebar-wrapper">
                        <div className="sidebar-brand">
                            <Link to="/">
                                <img alt="image" src="../../assets/images/logo.png" className="header-logo" />
                                <span className="logo-name ml-2">ReelApps</span>
                            </Link>
                        </div>  
                        <ul className="sidebar-menu">
                            <li className="dropdown active" style={{ display: 'block' }}>
                                <div className="sidebar-profile">
                                    <div className="siderbar-profile-pic">
                                        <img src={auth.user.avatar} className="profile-img-circle box-center"
                                            alt="User Image" />
                                    </div>
                                    <div className="siderbar-profile-details">
                                        <div className="siderbar-profile-name"> {auth.user.name}</div>
                                        <div
                                            className="siderbar-profile-position">{auth.user.is_root === '1' ? "Root" : 'Admin'}</div>
                                    </div>

                                </div>
                            </li>
                            <li className="menu-header">Main</li>
                            <li>
                                <Link className="nav-link" to="/dashboard"><i
                                    className="fas fa-desktop" /><span>Dashboard</span></Link>
                            </li>


                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false">
                                    <i className="fas fa-user" /><span>Users</span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link className="nav-link" to="/reelapp/add-user">Add New User</Link></li>
                                    <li><Link className="nav-link" to="/reelapp/add-bulk-user">Add Bulk User</Link></li>
                                    <li><Link className="nav-link" to="/view-user">View User</Link></li>
                                    <li><Link className="nav-link" to="/flagged-user">Flagged Users</Link></li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false"
                                >
                                    <i className="fa fa-cubes" /><span>Applications</span></Link>
                                <ul className="dropdown-menu">
                                    <li><Link className="nav-link" to="/add-application">Add
                                        Application</Link></li>
                                    <li><Link className="nav-link" to="/view-application">View Application</Link></li>
                                </ul>
                            </li>

                            {
                                auth.user.is_root === '1' ?
                                    <li className="dropdown">
                                        <Link to="#" className="nav-link has-dropdown" data-show="false"
                                        >
                                            <i className="fa fa-user" /><span>Admins</span></Link>
                                        <ul className="dropdown-menu">
                                            <li><Link className="nav-link" to="/add-admin">Add
                                                Admin</Link>
                                            </li>
                                            <li><Link className="nav-link" to="/view-admin">View Admin</Link></li>
                                        </ul>
                                    </li> : ''
                            }

                            {
                                auth.user.is_root === '1' ?
                                    <li><Link className="nav-link" to="/activity">
                                        <i className="fas fa-boxes" /><span>Activity</span></Link>
                                    </li> : ''
                            }
                            <li>
                                <Link className="nav-link" to="/activity-logs">
                                    <i className="fas fa-boxes" /><span>Activity Logs</span></Link>
                            </li>
                            <li><Link className="nav-link" to="/filter">
                                <i className="far fa-calendar-alt" /><span>Filter Data</span></Link>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false"
                                >
                                    <i className="fa fa-book" /><span>Tutorials</span></Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="nav-link" to="/add-category"> Add Category</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/view-category">View Category</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/add-article">Add Article</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/view-article">View Article</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/add-video">Add Videos</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/view-video">View Videos</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false"
                                >
                                    <i className="fa fa-cubes" /><span>Fonts</span></Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="nav-link" to="/add-font">
                                            Add Font
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/view-fonts">View Fonts</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false">
                                    <i className="fa fa-cubes" /><span>Webinar</span></Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="nav-link" to="/add-webinar">
                                            Add Webinar
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/view-webinar">View Webinar</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false">
                                    <i className="fa fa-cubes" /><span>Walkthrough</span></Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="nav-link" to="/add-walkthrough">
                                            Add Walkthrough
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/view-walkthrough">View Walkthrough</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="nav-link has-dropdown" data-show="false">
                                    <i className="fa fa-cubes" /><span>Black List Email</span></Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="nav-link" to="/view-blacklistemail">
                                            View Black List Email
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </aside>
                </div>
            </section>
        </div>
    );
}

export default Sidebar;
