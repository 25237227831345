const initialState = {
    token: false,
    loader : false,
    loginText: 'Login',
    isAuthenticated: false,
    user: false
}
export default function (state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_USER' :
            return {
                ...state,
                isAuthenticated: true,
                loader: false,
                loginText: 'Login',
                token: action.payload.token,
                user: action.payload.user
            }
        case 'USER_LOADING':
            return {
                ...state,
                loginText: 'Login...',
                loader: true
            }
        case 'LOGOUT':
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                loader: false,
                token: false,
                user: false
            }
        case 'USER_ERROR':
            localStorage.clear();
            return {
                ...state,
                loader: false,
                loginText: 'Login'
            }
        case 'UPDATE_ROOT_EMAIL':
            return {
                ...state,
                user: action.payload.user
            }
        case 'UPDATE_ROOT_NAME':
            return {
                ...state,
                user: action.payload.user
            }
        case 'UPDATE_ROOT_PASSWORD':
            return{
                ...state,
                user: action.payload.user
            }
        case 'UPDATE_ROOT_STATUS':
            return{
                ...state,
                user: action.payload.user
            }
        case 'UPDATE_ROOT_DELETED':
            return{
                ...state,
                user: action.payload.user
            }
        case 'UPDATE_IS_ROOT':
            return {
                ...state,
                user: action.payload.user
            }
        case 'UPDATE_ROOT_PROFILE':
            return {
                ...state,
                user: action.payload.user
            }
        default :
            return state;
    }
}
